import React from 'react';
import { LogoutOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
// import { UserOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';


const HeaderView = (props:any) => {
   
    const onLogout = () => {
        localStorage.clear();
        props.history.push('/login');
    }
   
    return (
        <Row justify="space-between" className="padding-m bg-secondary text-white">
            <Col>
               
            </Col>
            <Col>
                {/* <Tooltip placement="bottomRight" title={'Logout'}>
                    
                </Tooltip> */}
                <span onClick={onLogout} className="c-pointer">
                    <LogoutOutlined/> Log Out
                </span>
            </Col>
        </Row>
    );
}

export default withRouter(HeaderView)