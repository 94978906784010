import React, { useEffect, useState } from 'react';
import { Menu, Row } from 'antd';
import {useHistory, useLocation } from 'react-router-dom';
import { MenuFoldOutlined,
    MenuUnfoldOutlined,
    DashboardOutlined,
    BarChartOutlined,
    TeamOutlined,
    UserOutlined,
    SolutionOutlined,
    ShopOutlined,
    DownloadOutlined,
    FileExcelOutlined
    } from '@ant-design/icons';
import {ReactComponent as BusLogo} from '../../../assets/img/svg/IconBus.svg'
import {ReactComponent as OperatorLogo} from '../../../assets/img/svg/IconTicket.svg'
import {ReactComponent as PesoLogo} from '../../../assets/img/svg/IconPeso.svg'

const SideMenuView = (props:any) => {   
    const location = useLocation();
    const history = useHistory();

    const [currentPath, setCurrentPath] = useState(location.pathname);
   
    useEffect(() => {
        setCurrentPath(location.pathname);
    }, [location.pathname]);
   
   const toggleSidebar = () => {
       props.collapseToggle(!props.siderCollapsed)
   }

   const onToggleMenus = (action:string) => {
        setCurrentPath(action);
        history.push(action);
        
   }

   return (
        <Row>
            <Row align="middle" justify="end" style={{height: 58, width: '90%'}}>
                {props.siderCollapsed ? <MenuUnfoldOutlined onClick={toggleSidebar} /> : <MenuFoldOutlined onClick={toggleSidebar}/>}    
            </Row>
            <Menu className="text-primary" mode="inline" defaultSelectedKeys={[currentPath === '/' ? '/dashboard' : currentPath ]}>
                <Menu.Item className="text-primary" key="/dashboard" onClick={() => onToggleMenus('/dashboard')}>
                    <DashboardOutlined />
                    <span className="text-primary">Dashboard</span>
                </Menu.Item>
                <Menu.Item className="text-primary" key="/fare-matrix" onClick={() => onToggleMenus('/fare-matrix')}>
                    {/* <img src={FareIcon} className="margin-right-s" /> */}
                    <OperatorLogo className="margin-right-s" />
                    <span className="text-primary ">Fare Matrix</span>
                </Menu.Item>
                <Menu.Item className="text-primary" key="/operators" onClick={() => onToggleMenus('/operators')}>
                    {/* <img src={OperatorIcon} className="margin-right-s" /> */}
                    <BusLogo className="margin-right-s"  />
                    <span className="text-primary">Operators</span>
                </Menu.Item>
                <Menu.Item  className="text-primary" key="/riders" onClick={() => onToggleMenus('/riders')}>
                    <TeamOutlined />
                    <span className="text-primary" >Riders</span>
                </Menu.Item>
                <Menu.Item  className="text-primary" key="/terminal-port-management" onClick={() => onToggleMenus('/terminal-port-management')}>
                    <ShopOutlined />
                    <span className="text-primary" >Terminal Port Management</span>
                </Menu.Item>
                <Menu.Item  className="text-primary" key="/teller-management" onClick={() => onToggleMenus('/teller-management')}>
                    <SolutionOutlined />
                    <span className="text-primary" >Teller Management</span>
                </Menu.Item>
                <Menu.Item className="text-primary" key="/sales-report" onClick={() => onToggleMenus('/sales-report')}>
                    <BarChartOutlined />
                    <span className="text-primary">Sales Report</span>
                </Menu.Item>
                <Menu.Item className="text-primary" key="/downloadables" onClick={() => onToggleMenus('/downloadables')}>
                    <DownloadOutlined />
                    <span className="text-primary">Downloadables</span>
                </Menu.Item>
                <Menu.Item className="text-primary" key="/void" onClick={() => onToggleMenus('/void')}>
                    <FileExcelOutlined />
                    <span className="text-primary">Void</span>
                </Menu.Item>
                <Menu.Item className="text-primary" key="/payables" onClick={() => onToggleMenus('/payables')}>
                <PesoLogo className="margin-right-s"  />
                    <span className="text-primary">Payables</span>
                </Menu.Item>

                <Menu.Item className="text-primary" key="/users" onClick={() => onToggleMenus('/users')}>
                    <UserOutlined />
                    <span className="text-primary">Users</span>
                </Menu.Item>
            </Menu>
        </Row>
    );
}

export default SideMenuView