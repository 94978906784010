import React, { Component } from "react";
import "./App.css";
import axios from 'axios'
import Loader from "./components/Loader";
import lazyComponentLoader from './hoc/LazyLoader';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import ProtectedRoute from './hoc/ProtectedRoute';
import { isArray } from "util";
// import { message } from 'antd';
import DialogUtils from "./utils/DialogUtils";
import LayoutView from "./views/layout/LayoutView";
import AuthService from "./services/api/AuthService";
import PublicRoute from "./hoc/PublicRoute";

interface IAppState {
  isLoading:Boolean
  errorVisible:Boolean
}
const authService = new AuthService();
const LandingFail:any = lazyComponentLoader(() => import('./views/landingFail/LandingFailView'))
const Landing:any = lazyComponentLoader(() => import( './views/landing/LandingView'))
const Login:any = lazyComponentLoader(() => import('./views/auth/login/LoginView'));
const Forgot:any = lazyComponentLoader(() => import('./views/auth/forgot/ForgotView'));
const Reset:any = lazyComponentLoader(() => import('./views/auth/reset/ResetView'));
const Dashboard:any = lazyComponentLoader(() => import('./views/dashboard/DashboardView'));
const FareMatrix:any = lazyComponentLoader(() => import('./views/fare-matrix/FareMatrixView'));
const Operators:any = lazyComponentLoader(() => import('./views/operators/OperatorsView'));
const Riders:any = lazyComponentLoader(() => import('./views/riders/RidersView'));
const Payables:any = lazyComponentLoader(() => import('./views/payables/PayablesView'));
const SalesReport:any = lazyComponentLoader(() => import('./views/sales-report/SalesReportView'));
const Downloadables:any = lazyComponentLoader(() => import('./views/downloadables/DownloadablesView'));
const Void: any = lazyComponentLoader(() => import('./views/void/VoidView'));
const Users:any = lazyComponentLoader(() => import('./views/users/UsersView'));
const TerminalPortManagementView: any = lazyComponentLoader(() => import('./views/teller-port-management/TerminalPortManagementView'))
const TellerManagementView: any = lazyComponentLoader(() => import('./views/teller-management/TellerManagementView'))
class App extends Component<any, IAppState> {
  
  constructor(props:any) {
    super(props);
    //initialize state here
    this.state = {
        isLoading: false,
        errorVisible: true
    }

    const self = this;
    axios.interceptors.request.use(function (config) {
      // spinning start to show
      self.setState({isLoading: true});
      
      return config
    }, function (error) {
      return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
      // spinning hide
      self.setState({isLoading: false});
      
      return response;
    }, async (error) => {
      if (error.response) {
        this.showError(error.response.data.errors);
      } else {
        this.showError(error);
      }
      self.setState({isLoading: false});
      return Promise.reject(error);
    });
  }

  showError(errors:any)
  {
    let content:any = null;

    if(isArray(errors)) {
      // message.error(
        content = (< div>{
          errors.map( (item:any, index:number) => { return <div key={Math.random().toString(6)}>{item.message}</div>})
          }
        </div>)
    // );
    } else {
     content = errors.message.includes('Network Error') ? 'No Network Connection. Please make sure that Wi-Fi or Mobile Data is turned on, then try again.' : errors.message;
    }
    // const err = content? content.split('Error: ') : '';
    // DialogUtils.error(content);
    let showDialog:boolean = true;
    
    if(errors) {
      if(isArray(errors)) {
        {errors.map((value, index) => {
          if(value['context']['key'] == 'access_token' || value['context']['key'] == 'refresh_token') {
            showDialog = false;
          }
        })}
      }
    }
    if(showDialog) {
      DialogUtils.error(content);
    }
  }
  
  render() {

    return (
      <div>
        <Router>
            <Switch>
            <PublicRoute 
              auth={{ isLoggedIn: () => authService.isLoggedIn() }}
              exact path='/success'
              component={Landing}/>
            <PublicRoute 
              auth={{ isLoggedIn: () => authService.isLoggedIn() }}
              exact path='/failed'
              component={LandingFail}/>
              <PublicRoute 
              auth={{ isLoggedIn: () => authService.isLoggedIn() }}
              exact path='/login'
              component={Login}/>
               <PublicRoute 
              auth={{ isLoggedIn: () => authService.isLoggedIn() }}
              exact path='/reset-password/:token'
              component={Reset}/>
               <PublicRoute 
              auth={{ isLoggedIn: () => authService.isLoggedIn() }}
              exact path='/forgot-password'
              component={Forgot}/>
              <LayoutView>
                  <ProtectedRoute exact path={['/', '/dashboard']} component={Dashboard} />
                  <ProtectedRoute exact path={'/fare-matrix'} component={FareMatrix} />
                  <ProtectedRoute exact path={'/operators'} component={Operators} />
                  <ProtectedRoute exact path={'/riders'} component={Riders} />
                  <ProtectedRoute exact path={'/payables'} component={Payables} />
                  <ProtectedRoute exact path={'/sales-report'} component={SalesReport} />
                  <ProtectedRoute exact path={'/downloadables'} component={Downloadables} />
                  <ProtectedRoute exact path={'/void'} component={Void} />
                  <ProtectedRoute exact path={'/users'} component={Users} />
                  <ProtectedRoute exact path={'/terminal-port-management'} component={TerminalPortManagementView} />
                  <ProtectedRoute exact path={'/teller-management'} component={TellerManagementView} />
                  {/* REDIRECT TO HOME/LOGIN IF NO PAGE FOUND */}
                  <Route render={props => 
                    {
                      const { key }  = props.location;
                      const isLoggedIn = authService.isLoggedIn();
                      if (isLoggedIn && !key) {
                        return <Redirect from='*' to="/" />
                      } else if (!isLoggedIn){
                        return <Redirect from='*' to="/login" />
                      }
                    }
                  } />
                  {/* END */}
              </LayoutView>
            </Switch>
        </Router>
        
        {this.state.isLoading ? <Loader /> : null}
      </div>
    );  
  }
}

export default App;