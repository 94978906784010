import React, { useState } from "react";
import { Layout, Row, Col } from 'antd';
import SideMenuView from "../component/sidebar/SideMenuView";
import HeaderView from "../component/header/HeaderView";
import logo from '../../assets/img/svg/Logo.svg';
import FooterView from "../component/footer/FooterView";

const { Sider, Content } = Layout;

const LayoutView = (props:any) => {   

    const [collapsed, setCollapsed] = useState(false);

    const toggleCollapse = (collapsed: boolean) => {
        setCollapsed(collapsed);
    };


    return (
        <Layout>
             <Sider theme="light" trigger={null} collapsible collapsed={collapsed} onCollapse={toggleCollapse}>
                <SideMenuView siderCollapsed={collapsed} collapseToggle={toggleCollapse} />
                <div className="max-width" style={{position: 'absolute', bottom: 18, left: 0}}>
                    <Row className="max-width" align="middle" justify="center">
                        <Col className="text-secondary font-11">
                            Powered By:
                        </Col>
                        <Col offset={1}>
                            <img src={logo} alt="Topline" />
                        </Col>
                    </Row>
                </div>
            </Sider>
            <Layout>
                <HeaderView/>
                <Content style={{
                        padding: 24,
                        minHeight: 'calc(100vh - 100px)',
                        maxHeight: 'calc(100vh - 100px)',
                        overflow: 'auto'
                    }}>
                       {props.children}
                </Content>
                <FooterView />
            </Layout>  
        </Layout>   
    );
}


export default LayoutView